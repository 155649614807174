<template>
  <v-card>
    <v-layout row wrap>
      <v-flex xs12 sm12 md6 lg6>
        <v-list dense two-line dark>
          <v-list-tile avatar >
            <v-list-tile-avatar>
              <v-icon large>my_location</v-icon>
            </v-list-tile-avatar>
            <v-list-tile-content>
              <v-list-tile-sub-title>Estoque</v-list-tile-sub-title>
              <v-list-tile-title v-html="props.item.stock.name || '-'"></v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>
          <v-divider></v-divider>
          <v-list-tile avatar>
            <v-list-tile-avatar>
              <v-icon large>description</v-icon>
            </v-list-tile-avatar>
            <v-list-tile-content>
              <v-list-tile-sub-title>Descrição</v-list-tile-sub-title>
              <v-list-tile-title v-html="props.item.description || '-'"></v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>
        </v-list>
      </v-flex>
      <v-flex xs12 sm12 md6 lg6>
        <v-list dense two-line dark>
          <v-list-tile v-if="props.item.business_id" avatar @click="$router.push({name: props.item.business.info.name, params: {id: props.item.business_id}})">
            <v-list-tile-avatar>
              <v-icon large>vertical_split</v-icon>
            </v-list-tile-avatar>
            <v-list-tile-content>
              <v-list-tile-sub-title>{{props.item.business.info.title}}</v-list-tile-sub-title>
              <v-list-tile-title v-html="props.item.business_id ? '#' + props.item.business_id + ' (Clique para acessar)' : '-'"></v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>
          <v-divider></v-divider>
          <v-list-tile avatar>
            <v-list-tile-avatar>
              <v-icon large>swap_horiz</v-icon>
            </v-list-tile-avatar>
            <v-list-tile-content>
              <v-list-tile-sub-title>ID da Movimentação</v-list-tile-sub-title>
              <v-list-tile-title v-html="props.item.id || '-'"></v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>
        </v-list>
      </v-flex>
    </v-layout>
  </v-card>
</template>
<script>
  export default {
    name: 'StockMovementsExpand',
    props: {
      config: Object,
      props: Object
    }
  }
</script>